var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-final-modal',{attrs:{"classes":"modal-container","content-class":"modal-content","click-to-close":false},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('button',{staticClass:"modal__close btn",on:{"click":_vm.closeModal}},[_c('i',{staticClass:"bi bi-x"})]),_c('span',{staticClass:"modal__title"},[_vm._v(_vm._s(_vm.formTitle))]),_c('div',{staticClass:"modal__content py-2 px-2"},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"kategori_pekerjaan_id"}},[_vm._v("Kategori Pekerjaan")]),_c('v-select',{class:{
            'is-invalid': _vm.$v.editedItem.kategori_pekerjaan_id.$error,
          },attrs:{"options":_vm.kategoriPekerjaan,"reduce":function (kategori_pekerjaan_id) { return kategori_pekerjaan_id.id; },"label":"nama"},scopedSlots:_vm._u([{key:"search",fn:function(ref){
          var attributes = ref.attributes;
          var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",class:{
                'is-invalid': _vm.$v.editedItem.kategori_pekerjaan_id.$error,
              }},'input',attributes,false),events))]}}]),model:{value:(_vm.editedItem.kategori_pekerjaan_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "kategori_pekerjaan_id", $$v)},expression:"editedItem.kategori_pekerjaan_id"}})],1),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"nama"}},[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editedItem.nama),expression:"editedItem.nama"}],staticClass:"form-control",class:{ 'is-invalid': _vm.$v.editedItem.nama.$error },attrs:{"type":"text"},domProps:{"value":(_vm.editedItem.nama)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editedItem, "nama", $event.target.value)}}}),(_vm.$v.editedItem.nama.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.editedItem.nama.required)?_c('span',[_vm._v("Nama harus di isi.")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"modal__action mt-3"},[_c('button',{staticClass:"btn btn-primary mx-2",on:{"click":_vm.submitForm}},[_vm._v("Simpan")]),_c('button',{staticClass:"btn",on:{"click":_vm.closeModal}},[_vm._v("Batal")])])])}
var staticRenderFns = []

export { render, staticRenderFns }